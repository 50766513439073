import React from 'react'
import type { FunctionComponent } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { useRouter } from 'next/router'
import PowerButton from '../power/button'

const getTitleMessage = (statusCode?: number) => {
  if (statusCode === 401) return "Looks like you're not authenticated."
  if (statusCode === 404) return 'This page does not exist.'
  // default to 5xx error message
  return 'Oops, something went wrong on our side.'
}

const getSubtitleMessage = (statusCode?: number) => {
  if (!Boolean(statusCode))
    return "Sorry about that. Please try refreshing the page or re-entering this application from the brand's account."
  if (statusCode === 401)
    return `To activate a new session, please re-enter this application from the brand's account.`
  if (statusCode === 404) return 'Please try to go back to the previous page.'
  // default to 5xx error message
  return 'Sorry about that. Please try again now or later.'
}

type CallToActionProps = {
  statusCode?: number
}

const CallToAction: FunctionComponent<CallToActionProps> = ({ statusCode }) => {
  const router = useRouter()

  if (!statusCode) return null

  if (statusCode === 404 || statusCode >= 500) {
    return (
      <PowerButton
        style={{ width: 'fit-content' }}
        onClick={() => router.back()}
      >
        Go Back
      </PowerButton>
    )
  }

  return null
}

type Props = {
  statusCode?: number
  title?: string
}

export const ErrorTemplate: FunctionComponent<Props> = ({
  statusCode,
  title,
}) => {
  const titleMessage = title || getTitleMessage(statusCode)
  const subtitleMessage = getSubtitleMessage(statusCode)

  return (
    <Box
      display="flex"
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent="center"
      maxWidth={{ lg: 800 }}
      marginX={'auto'}
      data-cy={`${statusCode}-error-page`}
      sx={{
        px: 1,
        height: '100vh',
      }}
    >
      <Stack
        justifyContent={'center'}
        alignItems="center"
        spacing={2}
        mt={5}
        mb={3}
      >
        <Typography variant="h3" fontWeight={'bold'} textAlign="center">
          {statusCode && `${statusCode}: `}
          {titleMessage}
        </Typography>
        <Typography variant="body1" textAlign="center">
          {subtitleMessage}
        </Typography>
      </Stack>
      <CallToAction statusCode={statusCode} />
    </Box>
  )
}
