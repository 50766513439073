// Copied source from MUI's examples of interoping with NextJS.
// https://github.com/mui-org/material-ui/blob/93169002aec007d4c6c831951d6bc78ba1e6edbb/examples/nextjs-with-typescript/src/Link.tsx

import React from 'react'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import type { LinkProps as MuiLinkProps } from '@mui/material'
// Allow importing MUI Link here since this is our component that extends it with NextJS's linking magic
/* eslint-disable no-restricted-imports */
import { Link as MuiLink, styled } from '@mui/material'
import type { LinkProps as NextLinkProps } from 'next/link'
import NextLink from 'next/link'
/* eslint-enable no-restricted-imports */

// Add support for the sx prop for consistency with the other branches.
const Anchor = styled('a')({})

interface NextLinkComposedProps
  extends Omit<
      React.AnchorHTMLAttributes<HTMLAnchorElement>,
      'href' | 'onClick' | 'onMouseEnter' | 'onTouchStart'
    >,
    Omit<NextLinkProps, 'href' | 'as'> {
  to: NextLinkProps['href']
  linkAs?: NextLinkProps['as']
  href?: NextLinkProps['href']
}

const NextLinkComposed = React.forwardRef<
  HTMLAnchorElement,
  NextLinkComposedProps
>(function NextLinkComposed(props, ref) {
  const {
    to,
    linkAs,
    href: _href,
    replace,
    scroll,
    shallow,
    prefetch,
    locale,
    ...other
  } = props

  return (
    <NextLink
      href={to}
      prefetch={prefetch}
      as={linkAs}
      replace={replace}
      scroll={scroll}
      shallow={shallow}
      passHref
      locale={locale}
      // required in next 13.0 since anchor cannot be a child of a next-link
      legacyBehavior
    >
      <Anchor ref={ref} {...other} />
    </NextLink>
  )
})

export type LinkProps = {
  activeClassName?: string
  as?: NextLinkProps['as']
  href: NextLinkProps['href']
  linkAs?: NextLinkProps['as'] // Useful when the as prop is shallow by styled().
  noLinkStyle?: boolean
} & Omit<NextLinkComposedProps, 'to' | 'linkAs' | 'href'> &
  Omit<MuiLinkProps, 'href'>

// A styled version of the Next.js Link component:
// https://nextjs.org/docs/api-reference/next/link
const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(
  function Link(props, ref) {
    const {
      activeClassName = 'active',
      as: linkAs,
      className: classNameProps,
      href,
      noLinkStyle,
      ...other
    } = props

    delete other['role'] // Link don't have roles.

    const router = useRouter()
    const pathname = typeof href === 'string' ? href : href.pathname
    const className = clsx(classNameProps, {
      [activeClassName]: router.pathname === pathname && activeClassName,
    })

    const isExternal =
      typeof href === 'string' &&
      (href.startsWith('http') || href.startsWith('mailto:'))

    if (isExternal) {
      if (noLinkStyle) {
        return <Anchor className={className} href={href} ref={ref} {...other} />
      }

      return <MuiLink className={className} href={href} ref={ref} {...other} />
    }

    if (noLinkStyle) {
      return (
        <NextLinkComposed
          className={className}
          ref={ref}
          to={href}
          {...other}
        />
      )
    }

    return (
      <MuiLink
        component={NextLinkComposed}
        linkAs={linkAs}
        className={className}
        ref={ref}
        to={href}
        {...other}
      />
    )
  }
)

export default Link
