import type { ButtonProps } from '@mui/material'
import { Typography, Button } from '@mui/material'
import type { FunctionComponent } from 'react'
import Link from '../third-party/link'

const PowerButton: FunctionComponent<ButtonProps> = ({
  children,
  href,
  ...props
}) => {
  const isLink = href !== undefined
  const linkProps = isLink ? { href, component: Link } : {}

  const styles = {
    boxShadow: 0,
  }

  return (
    <Button
      {...linkProps}
      variant="contained"
      size="medium"
      sx={{ ...styles, ...props.sx }}
      {...props}
    >
      <Typography variant="subtitle1" component="p">
        {children}
      </Typography>
    </Button>
  )
}

export default PowerButton
